.free-users {
  position: relative;
  padding: 1rem;
  padding-left: 2rem;
}

.free-users-title {
    font-weight: bold;
    margin-bottom: 1rem;
}
