.plugin_menu_title {
    padding-bottom: 1rem;
    margin-left: 2rem;
    margin-top: 1rem;
    font-weight: bold;
}

.plugin_menu_label {
    padding: 0 0 1rem 2rem;
}

.plugin_menu_code_cont {
    margin-left: 2rem;
    padding: 1rem;
    border: 2px solid #497af2;
    border-radius: 5px;
    margin-bottom: 1rem;  

}

.plugin_menu_code_area {
    resize: none;
    border: none;
    overflow: hidden;
    width: 100%;
    min-height: 50px;
    max-height: 100px;
}

.plugin_menu_button_copy {
    float: right;
    margin-left: 1rem;
}

.plugin_menu_button_preview {
    float: right;
    margin-top: 0.5rem;
    font-weight: bold;
}

.plugin_menu_cont {
    max-width: 32rem;
    margin-right: 2rem;
}