.form-component {
  background: #f0f0f0;
  border-radius: 2px;
  height: 3em;
  outline: 0;
  border: 0;
  font-size: 0.9rem;
}
.form-time-component {
  background: #f0f0f0;
  border-radius: 2px;
  height: 3em;
  outline: 0;
  border: 0;
  font-size: 0.9rem;
  height: 2.5em;
  text-align: center;
}
.form-time-component--inline-container {
  display: inline-block;
  margin-right: 0.8rem;
}
.close_btn {
  background: url('../../styles/exit@2x.png');
  position: absolute;
  top: 0.8em;
  right: 0.8em;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-size: 100% 100%;
  z-index: 1;
}
.checkbox__container {
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
}
.checkbox__container__message {
  vertical-align: middle;
  display: inline-block;
  margin-left: 1.1em;
}
.checkbox__container__checkbox {
  border: 2px solid #757575;
  display: inline-block;
  border-radius: 4px;
  background: white;
  height: 25px;
  width: 25px;
  vertical-align: middle;
}
.checkbox__container__checkbox--checked {
  background: #497AF2;
  border-color: #497AF2;
}
.checkbox__container__checkbox--disabled {
  background: #757575;
  border: 2px solid #757575;
}
.checkbox__container__checkbox__tick {
  width: 14px;
  height: 16px;
  transform: translate(6px, 3px) rotate(45deg);
  overflow: hidden;
}
.checkbox__container__checkbox__tick_content {
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  height: 15px;
  width: 10px;
  position: absolute;
  top: 4px;
  left: 2px;
  transform: translate(-3px, -4px);
}
.checkbox__container__checkbox--radio {
  border-radius: 50%;
  border-color: #497AF2;
  width: 26px;
  height: 26px;
  background: white !important;
}
.checkbox__container__checkbox--radio .checkbox__container__checkbox__tick {
  border-radius: 50%;
  background: #497AF2;
  transform: none;
  margin: 3px auto;
  width: 16px;
  height: 16px;
}
.checkbox__container__checkbox--radio .checkbox__container__checkbox__tick_content {
  display: none;
}
