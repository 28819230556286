.billing_info {
  .your_plan__plan_container {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0rem; 
    padding-top: 0rem;
    background: transparent;
    button {
      white-space: nowrap;
    }
  }
  .your_plan_container_item {
    padding-bottom: 0.3rem;
  }
  .your_plan__plan_container__plan_info {
    padding-right: 0.5rem;
  }
}
