.address_input_icon {
    width: 0.9rem;
    height: 1.2rem;
    display: inline-block;
    vertical-align: middle;
    margin-right: 1rem;
    background: url('../images/map.png');
    background-size: cover;
}

.address_area_value, .address_area_value_edit {
    resize: none;
}

.address_area_value {
    padding-top: 1.8rem !important;
}

.address_area_value_min {
    padding-top: 1rem !important;
}

.address_area_value_edit {
    padding-top: 1rem !important;
    padding-left: 1rem;
    padding-bottom: 1rem;
    padding-right: 1.5rem;
}

.address_clear_btn_cont {
    position: absolute;
    right: 10px;
    top: 0px;
    height: 3rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.address_clear_btn {
    background-color: #f0f0f0;
    width: 20px;
    height: 20px;
    background-image: url("../images/material-clear-icon.svg");
    background-size: contain;
    background-repeat: no-repeat;
}
.address_container {
    position: relative;
}

@media only screen and (min-device-pixel-ratio: 1.25),
only screen and (min-resolution: 1.25dppx) {
    .address_input_icon {
        background: url('../images/map@2x.png');
        background-size: cover;
    }
}

@media only screen and (min-device-pixel-ratio: 1.5),
only screen and (min-resolution: 1.5dppx) {
    .address_input_icon {
        background: url('../images/map@3x.png');
        background-size: cover;
    }
}