.tag_component {
    input {
        max-width: none;
    }
}

.tag_component__title {
    display: flex;
    align-items: center;
    &>* {
        margin-left: 0.5rem;
    }
    &>*:first-child{
        margin-left: 0;        
    }
    .auto_select {
        flex-basis: 60%;
    }
}

.tag_component__title__plus {
    background: lightgray;
    border-radius: 50%;
    cursor: pointer;
    outline: 0;
    font-weight: bold;
    margin-left: 1rem;
}

.event_form_dialog__tags__item {
    max-width: 0;
    transition: all 0.5s ease;
    opacity: 0;
    max-height: 0;
    white-space: nowrap;
    overflow: hidden;
}

.event_form_dialog__tags__item--show {
    max-width: 500px;
    opacity: 1;
    max-height: 200px;
}

.event_form_dialog__tags__item--hide {
    opacity: 0;
    max-width: 500px;
}