.form-component {
  background: #f0f0f0;
  border-radius: 2px;
  height: 3em;
  outline: 0;
  border: 0;
  font-size: 0.9rem;
}
.form-time-component {
  background: #f0f0f0;
  border-radius: 2px;
  height: 3em;
  outline: 0;
  border: 0;
  font-size: 0.9rem;
  height: 2.5em;
  text-align: center;
}
.form-time-component--inline-container {
  display: inline-block;
  margin-right: 0.8rem;
}
.close_btn {
  background: url('../../styles/exit@2x.png');
  position: absolute;
  top: 0.8em;
  right: 0.8em;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-size: 100% 100%;
  z-index: 1;
}
.input_with_label__label {
  color: #727272;
  font-size: 0.8rem;
  font-weight: normal;
  transition: all 0.5s ease;
}
.input_with_label__label--focus {
  color: black;
}
.input_with_label__input {
  margin-top: 0.6rem;
}
