.week_day_selector {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 12rem;
  .rounded_checkbox{
    margin-left: 0.5rem;
  }
  
}
