.time_peace_button {
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 2px;
  background-color: #497af2;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24),
    0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
  color: white;
  border: 0;
  font-size: 0.9em;
  outline: 0;
  position: relative;
  cursor: pointer;
}

.time_peace_button--link {
  color: #737373;
  cursor: pointer;
  font-size: 0.9em;
  background: transparent;
  border: 0;
  outline: 0;
}
.time_peace_button--link-active {
  color: #497af2;
}

.time_peace_button--delete {
  color: #e74c3c;
  cursor: pointer;
  font-size: 0.9em;
  background: transparent;
  border: 0;
  outline: 0;
}

.time_peace_button--disabled {
  background: #737373;
}

.time_peace_button__options {
  background: white;
  position: absolute;
  top: 2.2rem;
  right: 0;
  text-align: right;
  z-index: 22;
  min-width: 12rem;
  overflow: hidden;
  transition: 0.5s ease all;
  border-bottom-left-radius: 5px;
  border: 1px solid #f0eded;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
}

.time_peace_button__options--open {
  max-height: 15rem;
}

.time_peace_button--link.time_peace_button--disabled {
  background: transparent;
  color: #737373 !important;
}

.time_peace_button__options--close {
  max-height: 0;
  border: 0;
}

.time_peace_button__option {
  height: 2rem;
  line-height: 2rem;
  color: #212121;
  padding-right: 0.5rem;
  padding-left: 1rem;
  cursor: pointer;
  &:hover {
    background: lightgray;
  }
}

.time_peace_button__arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7.5px 0 7.5px;
  border-color: white transparent transparent transparent;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translate(0, -50%);
  transition: all 0.5s ease;
}

.time_peace_button__arrow--open {
  transform: translate(0, -50%) rotate(0deg);
}

.time_peace_button__arrow--close {
  transform: translate(0, -50%) rotate(180deg);
}

.time_peace_button__container {
  position: relative;
  display: inline-block;
  height: 2.2rem;
  .time_peace_button {
    display: block;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 2.2rem;
  }
}
.time_peace_button--gray-style {
  color: #6e7d8a;
  border: 1px solid #6e7d8a;
  background: white;
  box-shadow: none;
}

.time_peace_button--blue-style-bold {
  color: #007aff;
  font-weight: bold;
  box-shadow: none;
}

.time_peace_button--blue-style {
  color: #007aff;
  box-shadow: none;
}

.time_peace_button--gray-border-style {
    border-radius: 2px;
    border: 2px solid gainsboro;
    padding: 0.3rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.toolbar_today_button {
  margin-left: 0.6rem;
  margin-right: 0.6rem;
}

@media only screen and (max-width: 750px) {
  .event_dialog{
    .time_peace_button--delete {
      right: 4rem !important;
      color: #fff !important;
      border-radius: 4px !important; 
      padding-top: 0.1rem !important;
      padding-bottom: 0.3rem !important;
      padding-left: 1rem !important;
      padding-right: 1rem !important;
      background-color: #9c0000 !important;
    }
  }
}

@media only screen and (max-width: 375px) {
  .event_dialog{
    .time_peace_button--delete {
      right: 3rem !important;
      padding: 0.3rem !important;
    }
  }
}