.manage__calendar__category__list__item {
  height: 3rem;
  background: #fafafa;
  line-height: 3rem;
  border-bottom: 1px solid #e3e6f1;
  padding-left: 1.2em;
  padding-right: 4.2em;
  position: relative;
}

.manage__calendar__category__list__item__button {
  position: absolute;
  right: 0.5em;
  top: 50%;
  transform: translate(0, -50%);
}

.manage_page {
  /*max-width: 62rem;*/
  margin: 0 auto;
  padding-left: 5%;
  padding-right: 5%;
}

.manage_page__title {
  margin-top: 3em;
  margin-bottom: 2em;
  line-height: 1.5;
  align-items: center;
  align-self: center;
  display: flex;
  justify-content: space-around;
  width: 40%;
}
.manage_page__content {
  display: flex;
  align-self: center;
  justify-content: space-between;
  .draggable_list {
    flex: 0 0 40%;
  }
  .getting_started {
    flex: 0 1 50%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.getting_started__title {
  font-weight: bold;
  margin-bottom: 1rem;
}
.getting_started__subtitle {
  margin-bottom: 0.5rem;
}
.manage_page__title__title {
  flex-grow: 1;
  font-size: 1.6rem;
}
.getting_started__list li {
  margin-bottom: 0.8rem;
}
.getting_started__footer {
  margin-top: 0.5rem;
}
.getting_started__check,
.getting_started__not_done {
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.8rem;
  background-size: contain !important;
}
.getting_started__check {
  background: url("./images/outline-check_box-24px.svg");
}
.getting_started__not_done {
  background: url("./images/outline-check_box_outline_blank-24px.svg");
}

.manage_page__title__button {
  flex: 0 0 10em;
  /*height: 2.8em;*/
}

.manage__calendar__category__list__item__handle {
  background: url("./images/hamburguer.png");
  width: 0.8em;
  height: 0.8em;
  display: inline-block;
  vertical-align: middle;
  margin-right: 2em;
  background-size: cover;
}

@media (max-width: 1050px) {
  .manage_page__content {
    display: block !important;
  }
  .manage_page__title {
    width: 100%;
  }
}
