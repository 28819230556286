.checkout_card_details,
.checkout_location {
  display: flex;
  & > * {
    flex: 1;
    margin-left: 1rem;
    &:first-child {
      margin-left: 0;
    }
  }
}

.StripeElement {
}
.checkout_form__button {
  width: 100%;
}

.checkout_form__button_checkout{
  background-image: url("./images/baseline_lock_white_18dp.png");
  background-repeat: no-repeat;
  background-size: 1.2rem;
  background-position-y: center;
  background-position-x: calc(50% - 50px);
}

.checkout_form__checkbox_text--organization-address {
  margin-bottom: 0.5rem;
}

.checkout_form {
  padding-bottom: 5rem;
  max-width: 40rem;
  
  .text_box {
    background-color: rgba(188, 188, 188, 0.1);
    border-radius: 2px;
    border-bottom-color: #6e7d8a;
    transition:none;
    .StripeElement {
      flex: 1;
      padding-top: 2.3rem;
      padding-left: 1rem;
    }

    .text_box__placeholder {
      color: #6e7d8a;
      //padding-left: 0.8rem;
    }

    .text_box__input--error{
      height: 4rem !important;

    }
    
  }

  .text_box__input {
    background-color: transparent;
    border-bottom-color: #6e7d8a;
    max-width: 100% !important;
    height: 2rem;
    padding-left: 1rem;
    padding-bottom: 0;
    padding-top: 0;
  }
  .checkbox__container__checkbox {
    vertical-align: top;
    border-width: 1px;
  }


}

.checkout_form__checkbox_text {
  color: #6e7d8a;
  padding-top: 0.1rem;
}

.checkout_form__info_text {
  color: #6e7d8a;
}

.checkout_form--subtitle_text{
  padding-top: 0.1rem;
  padding-bottom: 1rem;
}
.checkout_form--footer_text{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.checkout_form__checkbox_address {
  color: #364a5d;
  font-weight: bold;
}

.payment_address_input {
  background-color: rgba(188, 188, 188, 0.1);
  margin-top: 1rem;
  .auto_select__placeholder--normal {
    color: #6e7d8a;
  }
  .text_box__input--error {
    height: 4rem !important;
  }
  .auto_select__placeholder--top {
    color: #6e7d8a;
  }
}