.component_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1;
    
}

.component_overlay__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 10px;
    padding: 2em;
    width: 40%;
}
.component_overlay__confirm_buttons{
    margin-top: 2em;
    text-align: right;
    .time_peace_button {
        margin-left: 1em;
    }
}

.title_password_dialog {
    color: black;
    opacity: 1;
}

@media only screen and (max-width: 900px) {
    .event_dialog{
        .component_overlay__content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: white;
            border-radius: 10px;
            padding: 2em;
            width: 95%;
        }
    }
    
}