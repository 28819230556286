.account_page__content_container {
  display: flex;
  padding-left: 1rem;
}

.account_page__general_info {
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;
}
.account_page__content {
  .text_box__placeholder {
    top: 1rem;
  }
  .text_box__placeholder--normal {
    top: 1.625rem;
  }
  .text_box__input--focused {
    color: black;
    border-bottom-color: #4979f3 !important;
  }
  .text_box--with-material,
  .auto_select--with-material-placeholder {
    height: 4rem;
  }

  .text_box--with-material input {
    height: 3.5rem;
  }

  .text_box--with-material,
  .auto_select,
  .text_box__input {
    background-color: rgb(250, 250, 250);
  }

  .text_box__placeholder--top,
  .auto_select__placeholder--top {
    //padding: 0;
    color: #8e8e8e;
  }

  .text_box__input {
    padding-left: 1rem;

    max-width: 100%;
  }

  .auto_select {
    box-shadow: none;
  }

  .float-left {
    float: left;
  }

  .float-right {
    float: right;
    margin-right: 0;
  }

  .footer-control {
    padding-top: 1rem;
  }
}

.account_page__title {
  margin-bottom: 1rem;
  margin-left: 2rem;
  margin-top: 1rem;
  font-weight: bold;
}

.tabs {
  display: flex;
}
.tabs__item {
  padding: 1rem;
  .tabs__item__link {
    color: #364a5d;
    font-size: 1rem;
    font-weight: bold;
    line-height: (19/16) rem;
    text-align: center;
    text-decoration: none;
  }
}
.tabs__item--selected {
  .tabs__item__link {
    color: #007aff;
    border-bottom: 2px solid #007aff;
  }
}

.account_page__general_info__logo {
  height: 8rem;
}

.account_page__general_info__logo__image {
  height: 120px;
  width: 120px;
  background-color: #497af1;
  border-radius: 50%;
  cursor: pointer;
}

.account_page__general_info__logo__image_edit {
  width: 50px;
  height: 50px;
}

.account_page__general_info__logo_edit_cont {
  position: relative;
  bottom: 4.5rem;
  left: 2.5rem;
  cursor: pointer;
}

.account_page__general_info__org_name {
  color: black;
  font-size: (18/16) rem;
  font-weight: bold;
  line-height: (22/16) rem;
  text-align: center;
}

.account_page__content {
  flex: 1;
  padding-left: 1rem;
}

.account__profile__page {
  max-width: 600px;
  padding-right: 2rem;
}

.account__profile__edit--save {
  margin-left: 1rem;
}

.payments_layout__right {
  position: relative;
}
@media (max-width: 700px) {
  .account__profile__page {
    max-width: calc(100vw - 2rem);
    .container50 {
      display: block;
      *:first-child {
        margin-right: 0;
      }
    }
  }
}

@media (max-width: 460px) {
  .component_overlay__content.downgrade {
    padding: 2rem;
  }
  .your_plan {
    max-width: calc(100vw - 2rem);
  }
  .your_plan__plan_container {
    flex-wrap: wrap;
    justify-content: center;
  }
  .your_plan__plan_container__button_plan_info {
    margin-top: 1rem;
  }
  .your_plan__plan_container__name {
    text-align: center;
  }
  .your_plan_container_item {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 0.5rem;
  }
  .your_plan__plan_container__button_plan_info {
    flex: 100%;
    text-align: center;
  }
  .payments_layout__right__content {
    padding: 1rem;
    .component_overlay {
      position: absolute;
      min-width: 90%;

      h1 {
        font-size: 1rem;
        line-height: 1rem;
        margin-bottom: 1.5rem;
      }
      .downgrade__message {
        font-size: 0.9rem;
      }
      .downgrade__data__title {
        font-size: 0.95rem;
      }
      .downgrade__data__label {
        font-size: 0.8rem;
      }
      .downgrade__data__value {
        font-size: 0.9rem;
      }
      .downgrade_buttons_action_title,
      .downgrade_buttons_action_desc {
        font-size: 0.85rem;
      }
      .time_peace_button {
        font-size: 0.8rem;
        padding: 0.2rem;
      }
    }
  }

  .plan_card--free {
    margin-right: 0.5rem;
  }
  .plan_card--paid {
    margin-left: 0.5rem;
  }
  
  .plan_card__button_container {
    .time_peace_button {
      font-size: 0.8rem;
    }
  }
  .plan_card__name {
    font-size: 1.7rem;
  }
  .plan_price__monthly__currency {
    font-size: 1rem;
  }
  .plan_price__monthly__rate {
    font-size: 1.3rem;
  }
  .checkout_form--subtitle_text {
    font-size: 0.8rem;
  }
  .checkout_form__checkbox_text,
  .checkout_form__checkbox_address {
    font-size: 0.8rem;
  }
  .checkout_location {
    flex-wrap: wrap;
    & > * {
      margin-left: 0 !important;
      flex-basis: 100% !important;
    }
  }
  .payments_layout__right__content {
    .checkbox__container {
      display: flex;
    }
  }
  .plan_card--paid .plan_card__name_price_container{ 
    margin-top: 1.5rem;
  }
  .plan_card__features__feature__text {
    font-size: 0.8rem !important;
  }
}
