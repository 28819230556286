.plan_card {
  border-top: 0.5rem solid #007aff;
  max-width: 18rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.13);
  padding-top: 26rem/16;
  padding-bottom: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  .plan_price {
    margin-top: 1rem;
  }
}

.plan_card__name_price_container {
  display: flex;
  flex-direction: column;
  min-height: 100/16rem;
  justify-content: center;
}
.plan_card__name {
  margin-top: 0.5rem;
  text-align: center;
  color: #364a5d;
  font-size: 2rem;
}
.plan_card__name--free {
  font-size: 2rem;
}

.plan_card__features {
  margin-top: 0.5rem;
  margin-bottom: 38rem/16;
  flex: 1;
}
.plan_card__features__feature {
  margin-top: 1rem;
  text-align: center;
}
.plan_card__features__feature__tick {
}
.plan_card__features__feature__cross {
}
.plan_card__features__feature__text {
  color: #a7b0b8;
  font-size: 1rem;
}
.plan_card__features__feature__text--selected {
  color: #364a5d;
}
.plan_card__selected_marker {
  background: url("./images/current-plan.png");
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
  height: 30%;
  background-repeat: no-repeat;
}
.plan_card__button_container {
  text-align: center;
  button {
    width: 80%;
    font-size: 1rem;
    font-weight: bold;
  }
  .time_peace_button--gray-style {
    font-weight: normal;
  }
}
.plan_card--paid .plan_card__features__feature:first-child {
  margin-bottom: 1.5rem;
}
