.form-component {
  background: #f0f0f0;
  border-radius: 2px;
  height: 3em;
  outline: 0;
  border: 0;
  font-size: 0.9rem;
}
.form-time-component {
  background: #f0f0f0;
  border-radius: 2px;
  height: 3em;
  outline: 0;
  border: 0;
  font-size: 0.9rem;
  height: 2.5em;
  text-align: center;
}
.form-time-component--inline-container {
  display: inline-block;
  margin-right: 0.8rem;
}
.close_btn {
  background: url('../../styles/exit@2x.png');
  position: absolute;
  top: 0.8em;
  right: 0.8em;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-size: 100% 100%;
  z-index: 1;
}
.rounded_checkbox {
  transition: all 0.5s ease;
  border-radius: 50%;
  font-size: 0.7em;
  width: 2em;
  height: 2em;
  line-height: 2em;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}
.rounded_checkbox--checked {
  background: #497AF2;
  color: white;
  opacity: 1;
  font-weight: bold;
  /*box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);*/
}
.rounded_checkbox--unchecked {
  background: #f0f0f0;
  color: black;
  opacity: 0.8;
}
.rounded_checkbox__text {
  font-size: 0.8em;
}
.rounded_checkbox--square {
  border-radius: 0 !important;
}
@media (max-width: 900px) and (min-width: 700px) {
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
}
