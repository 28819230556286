.form-component {
  background: #f0f0f0;
  border-radius: 2px;
  height: 3em;
  outline: 0;
  border: 0;
  font-size: 0.9rem;
}
.form-time-component {
  background: #f0f0f0;
  border-radius: 2px;
  height: 3em;
  outline: 0;
  border: 0;
  font-size: 0.9rem;
  height: 2.5em;
  text-align: center;
}
.form-time-component--inline-container {
  display: inline-block;
  margin-right: 0.8rem;
}
.close_btn {
  background: url('../styles/exit@2x.png');
  position: absolute;
  top: 0.8em;
  right: 0.8em;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-size: 100% 100%;
  z-index: 1;
}
.layout {
  display: grid;
  min-height: 100vh;
  height: 100vh;
  grid-template-columns: minmax(225px, 230px) auto;
  grid-template-rows: 7rem 1fr;
}
.layout_left_bar {
  min-width: 205px;
  height: 100%;
  background: #fafafa;
}
.layout_left_bar__app {
  height: 7rem;
  background-color: #497af2;
  position: relative;
  grid-column: 1;
  grid-row: 1;
  transition: all 0.2s ease;
}
.layout_left_bar__app__name {
  padding: 1.1em;
  color: white;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 2em;
  font-weight: bold;
  font-size: 1.4em;
}
.layout_left_bar__app__name img {
  width: 100%;
}
.layout_left_nav_bar__nav_container {
  padding-top: 1.5em;
  background: #fafafa;
  grid-column: 1;
  grid-row: 2;
  width: 100%;
  height: 100%;
  padding-left: 12%;
  position: relative;
  transition: width 0.2s ease;
}
.layout_right_panel {
  flex: 1;
}
.layout_right_panel__title_bar {
  border-bottom: 1px solid #e0e0e0;
  height: 7rem;
  background: #fafafa;
  width: 100%;
  display: flex;
  line-height: 7em;
  padding-left: 5%;
  grid-column: 2;
  grid-row: 1;
  align-items: center;
}
.layout_right_panel__title_bar__title {
  font-size: 2em;
  flex: 1;
  /*overflow: hidden;*/
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
  width: 100%;
}
.layout_right_panel__title_bar__button_container {
  flex: 0 0 auto;
}
.layout_right_panel__title_bar__right_separator {
  flex: 0 0 5%;
}
.layout_right_panel__content {
  overflow: auto;
}
/*
@media only screen and (max-width:800px) and (min-width:750px) {
    .layout {
        grid-template-columns: minmax(180px, 20%) 1fr;
    }
    .layout_left_bar__app__name{
        padding: 0.7rem;
    }
}*/
.page_loader_container {
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.layout_right_panel__title_bar__hamburger {
  width: 1.2rem;
  height: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 2em;
  background: url("../images/hamburguer.png");
  background-size: cover;
  cursor: pointer;
  display: none;
}
.vertical_nav_bar__item--small-view {
  display: none;
}
.multi_select__options--layout_right_panel__title_bar__select_organization {
  margin-top: -2rem;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.39);
}
@media only screen and (max-height: 600px) {
  .vertical_nav_bar__item {
    margin-bottom: 1rem;
  }
}
@media only screen and (max-height: 450px) {
  .vertical_nav_bar__item {
    margin-bottom: 0.6rem;
  }
}
@media only screen and (max-width: 750px) {
  .layout {
    grid-template-rows: 3rem 1fr;
    grid-template-columns: 1fr;
  }
  .vertical_nav_bar__item--big-view {
    display: none;
  }
  .vertical_nav_bar__item--small-view {
    display: flex;
  }
  .layout_right_panel__title_bar__button_container {
    display: none;
  }
  .layout_right_panel__title_bar__hamburger {
    display: inline-block;
  }
  .layout_right_panel__title_bar {
    color: white;
    background: #497AF2;
    height: 100%;
    grid-column: 1;
    line-height: 3rem;
  }
  .multi_select__options--layout_right_panel__title_bar__select_organization {
    margin-top: 0;
    background: #497AF2 !important;
    color: white;
  }
  .layout_right_panel__title_bar__title {
    font-size: 1.5rem;
    text-align: right;
    width: 0vw !important;
  }
  .layout_left_nav_bar__nav_container {
    padding-left: 1rem;
    position: absolute;
    top: 5rem;
    left: 0;
    max-width: 16rem;
    width: 16rem;
    z-index: 2;
    overflow: hidden;
    height: 100%;
    display: inline-table;
  }
  .layout_left_bar__app__name {
    padding: 0.5em;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .layout_left_bar__app {
    position: absolute;
    left: 0;
    width: 16rem;
    z-index: 2;
    overflow: hidden;
    height: 5rem;
  }
  .layout_right_panel__content {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row: 3;
  }
  .layout__menu_backdrop {
    position: fixed;
    background: black;
    opacity: 0.6;
    width: 100%;
    top: 0%;
    left: 0;
    height: 100%;
    z-index: 1;
  }
  .layout_left_bar__app--closed {
    width: 0;
    padding: 0;
    overflow: hidden;
  }
  .layout_left_nav_bar__nav_container--closed {
    max-width: 0;
    padding: 0;
    overflow: hidden;
    display: none;
  }
  .layout_right_panel__content {
    grid-column: 1;
    grid-row: 2;
  }
  .layout_right_panel__title_bar__select_organization .multi_select__select__collapse_icon {
    border-color: transparent transparent white;
  }
  .ReactModal__Content {
    width: 100vw !important;
    min-width: 100vw !important;
    min-height: 100vh !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    transform: none !important;
    border: 0;
    border-radius: 0;
    padding: 0.5rem !important;
    padding-top: 5rem !important;
  }
  .ReactModal__Content h2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #3d78fa;
    margin: 0;
    padding: 0;
    line-height: 3rem;
    padding-left: 5rem;
    color: white;
    font-size: 1.3rem !important;
  }
  .ReactModal__Content .event_dialog__close_btn,
  .ReactModal__Content .simple_edition_dialog__close_btn {
    left: 2rem;
    top: 1.5rem;
    transform: translateY(-50%);
    background: url("./images/close-white.png");
    background-size: cover;
  }
  .ReactModal__Content .event_form_button__row button,
  .ReactModal__Content .simple_edition_dialog__buttons {
    position: absolute;
    top: 1.5rem;
    right: 0.5rem;
    transform: translateY(-50%);
    box-shadow: none;
    background: transparent;
  }
  .ReactModal__Content .event_form_button__row button .time_peace_button,
  .ReactModal__Content .simple_edition_dialog__buttons .time_peace_button {
    box-shadow: none;
    background: transparent;
  }
  .ReactModal__Content .event_form_button__row button .time_peace_button--disabled,
  .ReactModal__Content .simple_edition_dialog__buttons .time_peace_button--disabled {
    color: #e0e0e0;
  }
  .ReactModal__Content .event_form_button__row button .simple_edition_dialog__buttons__delete,
  .ReactModal__Content .simple_edition_dialog__buttons .simple_edition_dialog__buttons__delete {
    background: #9c0000 !important;
    color: white;
    padding: 0.2rem 0.5rem 0.2rem !important;
    border-radius: 4px;
  }
  .ReactModal__Content .event_form_button__row button .simple_edition_dialog__buttons__cancel,
  .ReactModal__Content .simple_edition_dialog__buttons .simple_edition_dialog__buttons__cancel {
    display: none;
  }
  .ReactModal__Content .simple_edition_dialog__buttons {
    margin: 0;
  }
  .ReactModal__Body--open #time-peace-app {
    display: none;
  }
  .ReactModal__Body--open .ReactModal__Overlay {
    position: static !important;
  }
  .ReactModal__Body--open .ReactModal__Content {
    position: static !important;
  }
}
@media only screen and (max-width: 470px) {
  .component_overlay__content {
    min-width: 80%;
  }
}
@media only screen and (max-width: 375px) {
  .ReactModal__Content .event_dialog__close_btn {
    left: 1rem;
  }
  .ReactModal__Content h2 {
    padding-left: 3rem;
  }
}
.layout_right_panel__title_bar__select_organization {
  overflow: visible;
  font-size: 2rem;
}
.layout_right_panel__title_bar__select_organization .multi_select__options {
  left: 0;
  right: auto;
  top: 5.5rem;
  line-height: 1;
}
.layout_right_panel__title_bar__select_organization .multi_select__select__label {
  width: auto;
  font-size: 2rem;
}
.layout_right_panel__title_bar__select_organization .multi_select__options__option {
  font-size: 1.4rem;
  line-height: 1.5rem;
  cursor: pointer;
}
.layout_right_panel__title_bar__select_organization .multi_select__options__option:hover {
  background: gray;
}
