.inline-loader {
  display: inline-block;
  opacity: 1;
  max-height: 50px;
  transition: all 0.5s ease;
}

.inline-loader__bounce {
  display: inline-block;
  height: 10px;
  margin-left: 15px;
  div {
    height: 10px;
    width: 10px;
  }
}

.inline-loader--hidden,
.loader--hidden {
  max-height: 0;
  opacity: 0;
}
.loader--full-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  .sk-spinner {
    width: 50px;
    height: 50px;
  }
}
