.downgrade__data {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
}

.downgrade__data__label {
  text-align: right;
  padding-right: 0.5rem;
  color: #6e7d8a;
  font-size: 14rem/16;
}
.downgrade__data__value {
  padding-right: 0.5rem;
  color: #364a5d;
  font-size: 1rem;
}

.downgrade {
  h1 {
    font-size: 24rem/16;
    text-align: center;
    margin-bottom: 10rem/16;
  }
  button {
    font-size: 1rem;
  }
  padding-top: 67rem/16;
  padding-left: 100rem/16;
  padding-right: 100rem/16;
  padding-bottom: 57rem/16;
  width: 60% !important;
  max-width: 860rem/16;
}
.downgrade__message {
  font-size: 18rem/16;
  text-align: center;
  color: #364a5d;
  margin-bottom: 24rem/16;
  max-width: 32rem;
  margin-left: auto;
  margin-right: auto;
}
.downgrade__data__title {
  color: #364a5d;
  font-size: 18rem/16;
  text-align: center;
  margin-bottom: 1rem;
  font-weight: bold;
}

.downgrade__buttons_row {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  & > * {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-left: 0;
    }
  }
}

.downgrade_buttons_action_title {
  text-align: center;
  padding-top: 1rem;
  font-weight: bold;
  color: #364a5d;
}

.downgrade_buttons_action_desc{
  text-align: center;
  padding-top: 0.5rem;
  color: #364a5d;
}


