.payment_plans__subtitle {
  color: #364a5d;
  font-size: 18rem/16;
}
.payment_plans__plans {
  display: flex;
  margin-top: 2rem;
  margin-bottom: 5rem;
  justify-content: space-between;
  max-width: 39rem;
  & > * {
    flex: 1;
  }
}
