.form-component {
  background: #f0f0f0;
  border-radius: 2px;
  height: 3em;
  outline: 0;
  border: 0;
  font-size: 0.9rem;
}
.form-time-component {
  background: #f0f0f0;
  border-radius: 2px;
  height: 3em;
  outline: 0;
  border: 0;
  font-size: 0.9rem;
  height: 2.5em;
  text-align: center;
}
.form-time-component--inline-container {
  display: inline-block;
  margin-right: 0.8rem;
}
.close_btn {
  background: url('../../styles/exit@2x.png');
  position: absolute;
  top: 0.8em;
  right: 0.8em;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-size: 100% 100%;
  z-index: 1;
}
.text_box {
  margin-top: 1.2rem;
  transition: all 0.5s ease;
}
.text_box--invisible {
  opacity: 0;
}
.text_box__input {
  border: 0;
  color: #535353;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  outline: 0;
  width: 100%;
  max-width: 330px;
  height: 2rem;
}
.text_box__input::placeholder {
  color: #535353;
}
.text_box__input--focused {
  color: black;
  border-bottom-color: #4979f3 !important;
}
.text_box__input--error {
  border-bottom-color: red !important;
  background-image: url("../images/material-error-icon.png");
  background-repeat: no-repeat;
  background-size: 1.5rem;
  background-position-x: right;
  background-position-y: center;
  background-position: calc(100% - 1rem);
}
.text_box__area {
  border: 0;
  color: rgba(0, 0, 0, 0.38);
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  outline: 0;
  width: 100%;
}
.text_box__area--focused {
  color: black;
  border-bottom-color: #4979f3 !important;
}
.text_box__area--error {
  border-bottom-color: red !important;
}
.text_box {
  display: flex;
}
.text_box__description {
  align-self: center;
  white-space: nowrap;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.text_box__counter__value {
  text-align: right;
  font-size: 0.7rem;
  color: #727272;
  margin-top: 0.4rem;
  padding-right: 0.7rem;
}
.text_box--with-material {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 4rem;
}
.text_box--with-material input {
  height: 2rem;
  padding: 1rem;
  padding-bottom: 0;
  padding-top: 1.6rem;
}
.text_box__placeholder {
  position: absolute;
  top: 1.8rem;
  left: 1rem;
  transition: all 0.3s ease;
  color: #d3d3d3;
  pointer-events: none;
}
.text_box__placeholder--top {
  font-size: 0.8rem;
  top: 1rem;
}
.text_box__placeholder--focused {
  color: #4979f3;
  font-size: 0.8rem;
  top: 1rem;
}
.text_box__error_desc {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  color: red;
}
.text_box--with-input {
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
}
.text_box--with-input.text_box--focused {
  border-bottom-color: #4979f3 !important;
}
.text_box--with-input.text_box--error {
  border-bottom-color: red !important;
}
