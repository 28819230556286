.your_plan__plan_container {
  background-color: rgba(188, 188, 188, 0.1);
  border-radius: 2px;
  padding-top: 30rem/16;
  padding-left: 33rem/16;
  padding-right: 24rem/16;
  padding-bottom: 24rem/16;
  button {
    white-space: nowrap;
  }
}
.your_plan__plan_container__name {
  color: #364a5d;
  font-size: 18rem/16;
  font-weight: bold;
  padding-bottom: 0.8rem;
}
.your_plan__plan_container__button_plan_info {
  align-self: center;
}

.your_plan__plan_container__msg {
  color: #364a5d;
  font-size: 1rem;
}
.your_plan__plan_container {
  display: flex;
  justify-content: space-between;
}
.your_plan {
  max-width: 400px;
  padding-right: 2rem;
}

.your_plan__title {
  color: #6e7d8a;
  font-size: 14rem/16;
  margin-bottom: 0.5rem;
  margin-top: 28rem/16;
}
