.form-component {
  background: #f0f0f0;
  border-radius: 2px;
  height: 3em;
  outline: 0;
  border: 0;
  font-size: 0.9rem;
}
.form-time-component {
  background: #f0f0f0;
  border-radius: 2px;
  height: 3em;
  outline: 0;
  border: 0;
  font-size: 0.9rem;
  height: 2.5em;
  text-align: center;
}
.form-time-component--inline-container {
  display: inline-block;
  margin-right: 0.8rem;
}
.close_btn {
  background: url('../styles/exit@2x.png');
  position: absolute;
  top: 0.8em;
  right: 0.8em;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-size: 100% 100%;
  z-index: 1;
}
.vertical_nav_bar__item {
  display: flex;
  font-size: 0.9em;
  margin-bottom: 2rem;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
}
.vertical_nav_bar__item:hover .vertical_nav_bar__item__icon__container__icon {
  transform: scale(1.2);
}
.vertical_nav_bar__item:hover .vertical_nav_bar__item__link__text {
  font-size: 1rem;
}
.vertical_nav_bar__item--selected .vertical_nav_bar__item__link__text {
  color: #497AF2;
}
.vertical_nav_bar__item__icon__container {
  flex: 0 0 2em;
  text-align: center;
}
.vertical_nav_bar__item__icon__container__icon {
  height: 1.3rem;
  display: inline-block;
  vertical-align: middle;
  transition: 0.2s ease all;
  transform-origin: 50% 50%;
}
.vertical_nav_bar__item__link__text {
  flex: 1;
  vertical-align: middle;
  margin-left: 2rem;
  font-size: 0.9rem;
  transition: 0.2s ease all;
  color: black;
}
.vertical_nav_bar__item__icon__container__icon--calendar {
  background: url('./images/calendar_icon/calendar_gray.png');
  background-size: cover;
  height: 1.3rem;
  width: 1.12666667rem;
}
.vertical_nav_bar__item__icon__container__icon--calendar-active {
  background: url('./images/calendar_icon/calendar_blue.png');
  background-size: cover;
  height: 1.3rem;
  width: 1.12666667rem;
}
@media only screen and (min-device-pixel-ratio: 1.25), only screen and (min-resolution: 1.25dppx) {
  .vertical_nav_bar__item__icon__container__icon--calendar {
    background: url('./images/calendar_icon/calendar_gray@2x.png');
    background-size: cover;
  }
  .vertical_nav_bar__item__icon__container__icon--calendar-active {
    background: url('./images/calendar_icon/calendar_blue@2x.png');
    background-size: cover;
  }
}
@media only screen and (min-device-pixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx) {
  .vertical_nav_bar__item__icon__container__icon--calendar {
    background: url('./images/calendar_icon/calendar_gray@3x.png');
    background-size: cover;
  }
  .vertical_nav_bar__item__icon__container__icon--calendar-active {
    background: url('./images/calendar_icon/calendar_blue@3x.png');
    background-size: cover;
  }
}
.vertical_nav_bar__item__icon__container__icon--plus {
  background: url('./images/plus_icon/plus_gray.png');
  background-size: cover;
  height: 1.3rem;
  width: 1.3rem;
}
.vertical_nav_bar__item__icon__container__icon--plus-active {
  background: url('./images/plus_icon/plus_blue.png');
  background-size: cover;
  height: 1.3rem;
  width: 1.3rem;
}
@media only screen and (min-device-pixel-ratio: 1.25), only screen and (min-resolution: 1.25dppx) {
  .vertical_nav_bar__item__icon__container__icon--plus {
    background: url('./images/plus_icon/plus_gray@2x.png');
    background-size: cover;
  }
  .vertical_nav_bar__item__icon__container__icon--plus-active {
    background: url('./images/plus_icon/plus_blue@2x.png');
    background-size: cover;
  }
}
@media only screen and (min-device-pixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx) {
  .vertical_nav_bar__item__icon__container__icon--plus {
    background: url('./images/plus_icon/plus_gray@3x.png');
    background-size: cover;
  }
  .vertical_nav_bar__item__icon__container__icon--plus-active {
    background: url('./images/plus_icon/plus_blue@3x.png');
    background-size: cover;
  }
}
.vertical_nav_bar__item__icon__container__icon--manage {
  background: url('./images/manage_icon/manage_gray.png');
  background-size: cover;
  height: 1.3rem;
  width: 1.66rem;
}
.vertical_nav_bar__item__icon__container__icon--manage-active {
  background: url('./images/manage_icon/manage_blue.png');
  background-size: cover;
  height: 1.3rem;
  width: 1.66rem;
}
@media only screen and (min-device-pixel-ratio: 1.25), only screen and (min-resolution: 1.25dppx) {
  .vertical_nav_bar__item__icon__container__icon--manage {
    background: url('./images/manage_icon/manage_gray@2x.png');
    background-size: cover;
  }
  .vertical_nav_bar__item__icon__container__icon--manage-active {
    background: url('./images/manage_icon/manage_blue@2x.png');
    background-size: cover;
  }
}
@media only screen and (min-device-pixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx) {
  .vertical_nav_bar__item__icon__container__icon--manage {
    background: url('./images/manage_icon/manage_gray@3x.png');
    background-size: cover;
  }
  .vertical_nav_bar__item__icon__container__icon--manage-active {
    background: url('./images/manage_icon/manage_blue@3x.png');
    background-size: cover;
  }
}
.vertical_nav_bar__item__icon__container__icon--users {
  background: url('./images/users_icon/users_gray.png');
  background-size: cover;
  height: 1.3rem;
  width: 1.9rem;
}
.vertical_nav_bar__item__icon__container__icon--users-active {
  background: url('./images/users_icon/users_blue.png');
  background-size: cover;
  height: 1.3rem;
  width: 1.9rem;
}
@media only screen and (min-device-pixel-ratio: 1.25), only screen and (min-resolution: 1.25dppx) {
  .vertical_nav_bar__item__icon__container__icon--users {
    background: url('./images/users_icon/users_gray@2x.png');
    background-size: cover;
  }
  .vertical_nav_bar__item__icon__container__icon--users-active {
    background: url('./images/users_icon/users_blue@2x.png');
    background-size: cover;
  }
}
@media only screen and (min-device-pixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx) {
  .vertical_nav_bar__item__icon__container__icon--users {
    background: url('./images/users_icon/users_gray@3x.png');
    background-size: cover;
  }
  .vertical_nav_bar__item__icon__container__icon--users-active {
    background: url('./images/users_icon/users_blue@3x.png');
    background-size: cover;
  }
}
.vertical_nav_bar__item__icon__container__icon--gear {
  background: url('./images/gear_icon/gear_gray.png');
  background-size: cover;
  height: 1.3rem;
  width: 1.3rem;
}
.vertical_nav_bar__item__icon__container__icon--gear-active {
  background: url('./images/gear_icon/gear_blue.png');
  background-size: cover;
  height: 1.3rem;
  width: 1.3rem;
}
@media only screen and (min-device-pixel-ratio: 1.25), only screen and (min-resolution: 1.25dppx) {
  .vertical_nav_bar__item__icon__container__icon--gear {
    background: url('./images/gear_icon/gear_gray@2x.png');
    background-size: cover;
  }
  .vertical_nav_bar__item__icon__container__icon--gear-active {
    background: url('./images/gear_icon/gear_blue@2x.png');
    background-size: cover;
  }
}
@media only screen and (min-device-pixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx) {
  .vertical_nav_bar__item__icon__container__icon--gear {
    background: url('./images/gear_icon/gear_gray@3x.png');
    background-size: cover;
  }
  .vertical_nav_bar__item__icon__container__icon--gear-active {
    background: url('./images/gear_icon/gear_blue@3x.png');
    background-size: cover;
  }
}
.vertical_nav_bar__item__icon__container__icon--plugin {
  background: url('./images/plugin_icon/plugin_gray.png');
  background-size: cover;
  height: 1.3rem;
  width: 1.3rem;
}
.vertical_nav_bar__item__icon__container__icon--plugin-active {
  background: url('./images/plugin_icon/plugin_blue.png');
  background-size: cover;
  height: 1.3rem;
  width: 1.3rem;
}
@media only screen and (min-device-pixel-ratio: 1.25), only screen and (min-resolution: 1.25dppx) {
  .vertical_nav_bar__item__icon__container__icon--plugin {
    background: url('./images/plugin_icon/plugin_gray@2x.png');
    background-size: cover;
  }
  .vertical_nav_bar__item__icon__container__icon--plugin-active {
    background: url('./images/plugin_icon/plugin_blue@2x.png');
    background-size: cover;
  }
}
@media only screen and (min-device-pixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx) {
  .vertical_nav_bar__item__icon__container__icon--plugin {
    background: url('./images/plugin_icon/plugin_gray@3x.png');
    background-size: cover;
  }
  .vertical_nav_bar__item__icon__container__icon--plugin-active {
    background: url('./images/plugin_icon/plugin_blue@3x.png');
    background-size: cover;
  }
}
.vertical_nav_bar__item__icon__container__icon--comments {
  background: url('./images/comments_icon/comments_gray.png');
  background-size: cover;
  height: 1.3rem;
  width: 1.3rem;
}
.vertical_nav_bar__item__icon__container__icon--comments-active {
  background: url('./images/comments_icon/comments_blue.png');
  background-size: cover;
  height: 1.3rem;
  width: 1.3rem;
}
@media only screen and (min-device-pixel-ratio: 1.25), only screen and (min-resolution: 1.25dppx) {
  .vertical_nav_bar__item__icon__container__icon--comments {
    background: url('./images/comments_icon/comments_gray@2x.png');
    background-size: cover;
  }
  .vertical_nav_bar__item__icon__container__icon--comments-active {
    background: url('./images/comments_icon/comments_blue@2x.png');
    background-size: cover;
  }
}
@media only screen and (min-device-pixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx) {
  .vertical_nav_bar__item__icon__container__icon--comments {
    background: url('./images/comments_icon/comments_gray@3x.png');
    background-size: cover;
  }
  .vertical_nav_bar__item__icon__container__icon--comments-active {
    background: url('./images/comments_icon/comments_blue@3x.png');
    background-size: cover;
  }
}
.vertical_nav_bar__item__icon__container__icon--out {
  background: url('./images/out_icon/out_gray.png');
  background-size: cover;
  height: 1.3rem;
  width: 1.3rem;
}
.vertical_nav_bar__item__icon__container__icon--out-active {
  background: url('./images/out_icon/out_blue.png');
  background-size: cover;
  height: 1.3rem;
  width: 1.3rem;
}
@media only screen and (min-device-pixel-ratio: 1.25), only screen and (min-resolution: 1.25dppx) {
  .vertical_nav_bar__item__icon__container__icon--out {
    background: url('./images/out_icon/out_gray@2x.png');
    background-size: cover;
  }
  .vertical_nav_bar__item__icon__container__icon--out-active {
    background: url('./images/out_icon/out_blue@2x.png');
    background-size: cover;
  }
}
@media only screen and (min-device-pixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx) {
  .vertical_nav_bar__item__icon__container__icon--out {
    background: url('./images/out_icon/out_gray@3x.png');
    background-size: cover;
  }
  .vertical_nav_bar__item__icon__container__icon--out-active {
    background: url('./images/out_icon/out_blue@3x.png');
    background-size: cover;
  }
}
/*
.vertical_nav_bar__item__icon__container__icon--calendar {
    background: url('./images/calendar.png');
    background-size: cover;
    height: 1.5rem;
    width: 1.3em;
}

.vertical_nav_bar__item__icon__container__icon--manage {
    background: url('./images/manage.png');
    background-size: cover;
    width: 1.66em;
}

.vertical_nav_bar__item__icon__container__icon--logout {
    background: url('./images/users.png');
    background-size: cover;
    width: 1.82em;
}

@media only screen and (min-device-pixel-ratio: 1.25),
only screen and (min-resolution: 1.25dppx) {
    .vertical_nav_bar__item__icon__container__icon--calendar {
        background: url('./images/calendar@2x.png');
        background-size: cover;
    }
    .vertical_nav_bar__item__icon__container__icon--manage {
        background: url('./images/manage@2x.png');
        background-size: cover;
    }
    .vertical_nav_bar__item__icon__container__icon--logout {
        background: url('./images/users@2x.png');
        background-size: cover;
    }
}

@media only screen and (min-device-pixel-ratio: 1.5),
only screen and (min-resolution: 1.5dppx) {
    .vertical_nav_bar__item__icon__container__icon--calendar {
        background: url('./images/calendar@3x.png');
        background-size: cover;
    }
    .vertical_nav_bar__item__icon__container__icon--manage {
        background: url('./images/manage@3x.png');
        background-size: cover;
    }
    .vertical_nav_bar__item__icon__container__icon--logout {
        background: url('./images/users@3x.png');
        background-size: cover;
    }
}
*/
@media only screen and (max-width: 600px) {
  .vertical_nav_bar__item__link__text {
    margin-left: 0.5rem;
  }
}
.vertical_nav_bar__app_info {
  position: relative;
  text-align: left;
  bottom: 1rem;
  left: 0;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.vertical_nav_bar__app_info_org_cont {
  text-align: center;
  width: calc(100% - 25px);
  padding-bottom: 2rem;
}
.vertical_nav_bar__app_info_org_icon {
  background: #497af2;
  border-radius: 50%;
  color: #fff;
  width: 50px;
}
.vertical_nav_bar__app_info_org_organization {
  font-weight: bold;
}
.vertical_nav_bar__app_info_org_administrator {
  margin-top: 1rem;
}
.vertical_nav_bar__app_info_org_sep {
  border: 0;
  border-top: 1px solid #dcdcdc;
  margin-top: 1.3rem;
  padding: 0;
}
.vertical_nav_bar__app_info__line {
  color: #727272;
  font-size: 0.8rem;
  line-height: 0.8rem;
  margin-top: 0.8rem;
  text-align: center;
  width: calc(100% - 25px);
}
.vertical_nav_bar__app_info__line a {
  color: #727272;
  font-size: 0.8rem;
  cursor: pointer;
  text-decoration: none;
}
.vertical_nav_bar__app_info__line a:hover {
  text-decoration: underline;
}
/*
@media only screen and (max-width:750px) {
    .vertical_nav_bar {
        display: flex;
        width: 100%;
        justify-content: space-around;
        height: 3rem;
        align-content: center;
    }
    .vertical_nav_bar__app_info {
        position: static;
    }
    .vertical_nav_bar__item {
        flex: none;
        margin: 0;
        white-space: nowrap;
    }
}*/
