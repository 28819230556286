.form-component {
  background: #f0f0f0;
  border-radius: 2px;
  height: 3em;
  outline: 0;
  border: 0;
  font-size: 0.9rem;
}
.form-time-component {
  background: #f0f0f0;
  border-radius: 2px;
  height: 3em;
  outline: 0;
  border: 0;
  font-size: 0.9rem;
  height: 2.5em;
  text-align: center;
}
.form-time-component--inline-container {
  display: inline-block;
  margin-right: 0.8rem;
}
.close_btn {
  background: url('../../styles/exit@2x.png');
  position: absolute;
  top: 0.8em;
  right: 0.8em;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-size: 100% 100%;
  z-index: 1;
}
.profile_edit_dialog__close_btn {
  background: url('../../styles/exit@2x.png');
  position: absolute;
  top: 0.8em;
  right: 0.8em;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-size: 100% 100%;
  z-index: 1;
}
.profile_edit_dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  background: white;
  width: 60% !important;
  padding-top: 1.5rem;
  padding-bottom: 1.2rem;
  padding-right: 0.8rem !important;
  max-width: 570px;
}
.profile_edit_dialog__title {
  margin-top: 1rem;
  font-weight: bold;
}
.profile_edit__right_row {
  text-align: right;
  padding-left: 1rem;
  margin-top: 1rem;
}
.profile_edit_dialog__buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 4rem;
  margin-left: 1.7rem;
}
.profile_edit_dialog__buttons > * {
  margin-right: 1.2em;
}
.profile_edit_dialog__buttons > *:last-child {
  margin-right: 0;
}
.profile_edit_dialog__buttons__delete {
  color: #e74c3c;
}
.profile_edit_dialog__buttons__cancel {
  color: #497AF2;
}
.profile_edit__row {
  display: flex;
  margin-top: 1.5rem;
}
.profile_edit__row > * {
  flex: 0 0 50%;
  padding-left: 1rem;
  padding-right: 1rem;
}
.profile_edit_page {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 3rem;
}
.profile_edit_page .text_box__input {
  max-width: none;
  padding-left: 0.625rem;
}
.profile_edit_page .loader {
  align-self: center;
}
.profile_edit_page .input_with_label__label {
  padding-left: 0.625rem;
}
.profile_edit_page .text_box__area {
  padding: 1rem;
  color: black;
}
.profile_edit_page .input_with_label__label--focus {
  color: #4979f3 !important;
}
.profile_edit__image_cell__container {
  display: flex;
  justify-content: space-between;
}
.profile_edit__image_cell__message {
  color: #727272;
  padding-left: 1.5rem;
  padding-right: 0rem;
  align-self: flex-end;
}
.profile_edit__image_cell__message:first-child {
  padding-left: 0;
  align-self: flex-start;
}
.profile_edit__image_cell__message * {
  margin-top: 1rem;
  font-size: 0.9rem;
}
.profile_edit__image_cell__message *:first-child {
  margin-top: 0;
}
.profile_edit__image_cell__label {
  display: block;
  color: #727272;
  font-size: 0.8rem;
  font-weight: 400;
  transition: all 0.5s ease;
}
.profile_edit__image_cell__icon {
  margin-top: 0.6rem;
  flex: 0 0 10rem;
  width: 10rem;
  height: 10rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  transition: all 0.5s ease;
}
.profile_edit__image_cell__icon img {
  width: 100%;
  height: 100%;
  opacity: 1;
  object-fit: cover;
}
.profile_edit__image_cell__banner {
  margin-top: 0.6rem;
  height: 10rem;
  max-width: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  transition: all 0.5s ease;
}
.profile_edit__image_cell__banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
}
.profile_edit__image_cell__upload_container {
  text-align: right;
  margin-top: 0.6rem;
}
.profile_edit__personal_contacts {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.profile_edit__row--small-margin {
  margin-top: 0.5rem;
}
.profile_edit__row__area .text_box__area {
  background: #f0f0f0;
  border-radius: 3px;
}
.profile_edit__error {
  color: #e74c3c;
  padding-right: 1rem;
  align-self: center;
}
.profile_edit__row__address_text .auto_select {
  width: 95%;
  display: inline-block;
  box-shadow: none;
}
.profile_edit__row__address_text .address_more_info_btn__container {
  display: inline-block;
  width: 5%;
  text-align: right;
}
.address_more_info_btn__open {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 5px;
  border-color: transparent transparent #737373;
  transition: all 0.5s ease 0.2s;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  margin-top: 2.5px;
}
.address_more_info_btn__open--open {
  transform: rotate(-180deg);
}
.address_more_info_btn__open--close {
  transform: rotate(0deg);
}
.profile_edit__address_info {
  overflow: hidden;
  transition: all 0.5s ease;
}
.profile_edit__address_info .input_with_label {
  margin-top: 1rem;
}
.profile_edit__address_info--open {
  max-height: 25rem;
}
.profile_edit__address_info--closed {
  max-height: 0px;
}
.profile__no_editable_row {
  font-size: 1rem;
  /*margin-top: 1rem;*/
  color: #727272;
}
.profile__no_editable_row a {
  font-size: 1rem;
  color: #727272 !important;
}
.profile_edit__row--hiden {
  display: none !important;
}
.profile_edit__row__contact_title {
  padding-left: 1rem;
  margin-top: 1.5rem;
}
.profile_edit__row__contact_title .time_peace_button--link {
  margin-left: 1rem;
  color: #497af2;
}
.profile_edit__row__add_container {
  text-align: right;
}
.profile_edit__row__address_text__not_editable_text {
  border: 0;
  color: #535353;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  outline: 0;
  width: 100%;
  margin-top: 0.6rem;
  padding-bottom: 0.1rem;
}
@media (max-width: 870px) {
  .profile_edit__row {
    display: block;
  }
  .profile_edit__row > *:not(:first-child) {
    margin-top: 1.5rem;
  }
}
.profile_edit__image_cell__icon--hidden img {
  opacity: 0;
}
.profile_edit__image_cell__banner--hidden img {
  opacity: 0;
}
