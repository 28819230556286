@table-horizontal-left-padding: 2.5rem;
@table-horizontal-right-padding: 1rem;
.organization_list {
}

.organization_list__item {
  display: flex;
  height: 2.5rem;
  line-height: 2.5rem;
  background: #fafafa;
  border-bottom: 1px solid #c7c7c7;
  padding-left: 0.5rem;
  cursor: pointer;
  transition: all 0.5s ease;
  &:hover {
    font-weight: bold;
  }
}

.organization_list__item--selected {
  background: #497af2;
  color: white;
}

.admins_page {
  padding: 2em;
  padding-top: 0;
}

.admins_page_title {

    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: bold;
}

.admins_page__content {
  display: flex;
  justify-content: space-between;
  & > * {
    flex: 1;
    min-width: 0;
  }
}

.user_list__item__name,
.user_list__item__email,
.user_list__item__btn {
  color: black;
  padding: 0 !important;
}

.admins_page__content--with-org-selector {
  & > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    &:first-child {
      padding-left: 0;
    }
    &::last-child {
      padding-right: 0;
    }
  }
}

.user_list__item__row {
  display: flex;
  margin-bottom: 2.5rem;
  align-items: center;
}

.user_list__item__name {
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.user_list__item__row__name_and_email {
  flex: 1 1 auto;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.user_list__item__email {
  margin-top: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.user_list__item__btn {
  text-align: right;
  flex: 0 0 auto;
  margin-left: 5px;
  .user_list__item__btn__link {
    color: #497af2;
    font-weight: bold;
  }
}

.admins_page__error_overlay {
  z-index: 2;
}

.user_list__list {
  width: 100%;
  margin-top: 0.5rem;
  min-width: 0;
}

.user_list__item {
  min-width: 0;
  margin-top: 0.5rem;
}

.user_list__search_box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 2rem;
  max-width: 500px;
  .text_box {
    height: 2.5rem;
    margin-top: 0;
    flex: 0 0 100%;
    padding-right: 0;
  }
  .text_box__input {
    background: #f0f0f0;
    max-width: 100%;
  }
  .time_peace_button {
    margin-top: 0.5rem;
  }
}

.user_list--disabled {
  opacity: 0.6;
}

.admins_page__success {
  color: greenyellow;
  margin-top: 1.5rem;
}

.admins_page__error {
  margin-top: 1.5rem;
  color: red;
}

.admins_page__create {
  margin-top: 3rem;
  h3 {
    margin-bottom: 1.5rem;
  }
  .input_with_label {
    margin-top: 0.5rem;
  }

  .input_with_label__label--focus {
    color: #4979f3 !important;
  }

  .text_box__input,
  .input_with_label__label {
    padding-left: 0.625rem;
  }
}

.admins_page__save {
  margin-top: 1rem;
  margin-left: 1rem;
}

.page_selector {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 0.5rem;
}

.page_selector__item {
  flex: 0 0 auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  .time_peace_button--link {
    color: #727272;
  }
}

.page_selector__item--selected {
  .time_peace_button--link {
    color: #497af2;
  }
}

.user_list {
  max-width: 500px;
}

.user_list__item__btn__cancel {
  font-weight: normal !important;
  margin-right: 1rem;
}

.org_list_item_div {
  text-overflow: ellipsis;
  width: 90%;
  display: block;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
}

.user_list_admin_box {
  display: block;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
  border-width: 2px;
  border-style: groove;
  border-color: #497af2;
  border-image: initial;
}

.user_list_admin_box_title {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.user_list_non_admin {
  padding-left: 1rem;
}
