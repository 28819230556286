.form-component {
  background: #f0f0f0;
  border-radius: 2px;
  height: 3em;
  outline: 0;
  border: 0;
  font-size: 0.9rem;
}
.form-time-component {
  background: #f0f0f0;
  border-radius: 2px;
  height: 3em;
  outline: 0;
  border: 0;
  font-size: 0.9rem;
  height: 2.5em;
  text-align: center;
}
.form-time-component--inline-container {
  display: inline-block;
  margin-right: 0.8rem;
}
.close_btn {
  background: url('../../styles/exit@2x.png');
  position: absolute;
  top: 0.8em;
  right: 0.8em;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-size: 100% 100%;
  z-index: 1;
}
.new_category_dialog__close_btn {
  background: url('../../styles/exit@2x.png');
  position: absolute;
  top: 0.8em;
  right: 0.8em;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-size: 100% 100%;
  z-index: 1;
}
.new_category_dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  background: white;
  width: 60% !important;
  padding-top: 1.5rem;
  padding-bottom: 1.2rem;
  padding-right: 0.8rem !important;
}
.new_category_dialog__title {
  color: #727272;
  font-size: 1.25em;
  margin-top: 0.5em;
}
.category_dialog__label {
  display: block;
  font-size: 0.8rem;
  line-height: 1.25rem;
  color: #727272;
  margin-top: 0.8rem;
}
.category_dialog__input {
  margin-top: 0;
}
.category_dialog__buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 4rem;
  margin-left: 1.7rem;
}
.category_dialog__buttons > * {
  margin-right: 1.2em;
}
.category_dialog__buttons > *:last-child {
  margin-right: 0;
}
