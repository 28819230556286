@blue-shade-1: #497AF2;
@form-input-background: #f0f0f0;
@gray-shade1: #727272;

.form-component {
    background: @form-input-background;
    border-radius: 2px;
    height: 3em;
    outline: 0;
    border: 0;
    font-size: 0.9rem;
}

.form-time-component {
    .form-component;
    height: 2.5em;
    text-align: center;
}

.form-time-component--inline-container {
    display: inline-block;
    margin-right: 0.8rem;
}

.close_btn {
    background: url('./exit@2x.png');
    position: absolute;
    top: 0.8em;
    right: 0.8em;
    cursor: pointer;
    width: 16px;
    height: 16px;
    background-size: 100% 100%;
    z-index: 1;
}
.grid-template-rows(@i) when (@i> 0) {
    .grid-template-rows(@i - 1);
    grid-template-rows+_: 1fr;
}
