.form-component {
  background: #f0f0f0;
  border-radius: 2px;
  height: 3em;
  outline: 0;
  border: 0;
  font-size: 0.9rem;
}
.form-time-component {
  background: #f0f0f0;
  border-radius: 2px;
  height: 3em;
  outline: 0;
  border: 0;
  font-size: 0.9rem;
  height: 2.5em;
  text-align: center;
}
.form-time-component--inline-container {
  display: inline-block;
  margin-right: 0.8rem;
}
.close_btn {
  background: url('../../styles/exit@2x.png');
  position: absolute;
  top: 0.8em;
  right: 0.8em;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-size: 100% 100%;
  z-index: 1;
}
.signup_buttons {
  margin-top: 1.2rem;
  display: flex;
  justify-content: flex-end;
}
.signup_buttons .time_peace_button--link {
  margin-right: 2.5rem;
}
.orga_requests__page {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 3rem;
}
.orga_requests__page__request_list {
  background: whitesmoke;
}
.orga_requests__page__request_list__item {
  border-bottom: 1px dashed gray;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}
.orga_requests__page__request_list__item:hover {
  background: #353535;
  color: white;
}
.orga_requests__page__admin_name {
  display: flex;
}
.orga_requests__page__admin_name > * {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  flex: 1;
}
.orga_requests__page__admin_name > *:first-child {
  margin-left: 0;
}
.orga_requests__page__admin_name > *:last-child {
  margin-right: 0;
}
.login_page__left .text_box__placeholder--normal,
.login_page__left .auto_select__placeholder--normal {
  color: #6e7d8a;
}
.orga_requests__page__details__details {
  border-radius: 5px;
  border: 1px solid #e2e2e2;
  background: #f1f1f1;
  padding: 1rem;
}
.orga_requests__page {
  display: flex;
}
.orga_requests__page > * {
  flex: 0 0 50%;
  padding: 0.5rem;
}
.orga_requests__page__details__line {
  margin-bottom: 1rem;
}
.orga_requests__page__details__line label {
  font-weight: bold;
  margin-right: 0.5rem;
}
.orga_requests__page__details__buttons {
  text-align: right;
  margin-top: 1.5rem;
}
.orga_requests__page__details__buttons * {
  margin-right: 1rem;
}
.orga_requests__page__details__select {
  margin: 1rem;
}
.signin_page__footnote {
  font-size: 0.7rem;
  margin-top: 1rem;
  color: lightgray;
  text-align: left;
}
.signup_terms_and_conditions {
  color: #737373;
  font-size: 0.9rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.signup_terms_and_conditions a {
  font-size: 0.9rem;
  font-style: normal;
  text-decoration: none;
  color: #497af2;
}
.signin_page {
  align-self: center;
  width: 100%;
}
.signin_page .text_box,
.signin_page .auto_select {
  margin-top: 0rem;
  box-shadow: none;
  max-width: 100%;
}
.signin_page .text_box input,
.signin_page .auto_select input {
  max-width: 100%;
}
.signin_page .text_box input::-webkit-input-placeholder,
.signin_page .auto_select input::-webkit-input-placeholder {
  color: lightgray;
}
.signin_page .text_box input::-moz-placeholder,
.signin_page .auto_select input::-moz-placeholder {
  color: lightgray;
}
.signin_page .text_box input::-ms-placeholder,
.signin_page .auto_select input::-ms-placeholder {
  color: lightgray;
}
.signin_page .text_box input::placeholder,
.signin_page .auto_select input::placeholder {
  color: lightgray;
}
.signin_page .auto_select__item {
  background: white;
  border-bottom: gray;
  text-align: left;
}
.signin_page .auto_select__item--highlight {
  background: lightgray;
}
.signin_page__accept_terms {
  margin-top: 1rem;
  display: flex;
}
.signin_page__accept_terms .checkbox__container__checkbox {
  width: 20px;
  height: 20px;
}
.signin_page__accept_terms .checkbox__container__message {
  color: rgba(110, 125, 138, 0.82);
  font-size: 0.875rem;
}
.signin_page__accept_terms a {
  font-size: 0.875rem;
}
.signin_page__accept_terms .checkbox__container__checkbox__tick {
  transform: translate(3px, 1px) rotate(45deg);
}
.signin_page__accept_terms.checkbox__container {
  text-align: left;
  line-height: 1.3rem;
}
.signup__button {
  height: 3rem;
  box-shadow: none;
  transition: all 0.5s ease;
  border-radius: 3px;
}
.signup__button.time_peace_button--disabled {
  background: rgba(202, 207, 212, 0.6);
}
.signup__button.time_peace_button--link {
  text-decoration-line: underline;
}
.signup__link {
  height: 3rem;
  box-shadow: none;
  transition: all 0.5s ease;
  border-radius: 3px;
  color: #497af2;
}
.signup__link.time_peace_button--disabled {
  background: rgba(202, 207, 212, 0.6);
}
.signup__link--underline {
  text-decoration-line: underline !important;
}
.signup_failure_reason_item {
  text-align: left;
  padding-bottom: 0.5rem;
}
@media (max-width: 380px) {
  .signin_page__accept_terms .checkbox__container__checkbox {
    width: 26px;
  }
}
@media (max-width: 870px) {
  .signup_buttons {
    justify-content: space-around;
  }
  .orga_requests__page {
    display: block;
  }
}
.login_page__success__description {
  color: #364a5d;
  font-size: 18px;
  line-height: 24px;
}
.login_page_authentication_password_reset {
  float: right;
  margin-top: 1rem;
  margin-right: 0 !important;
}
.login_page__success__reasons__title {
  color: #364a5d;
  font-size: 16px;
  line-height: 21px;
  margin-top: 3.25rem;
}
.login_page__success__reasons__title .time_peace_button--link {
  padding: 0;
}
.login_signup_almost_reasons_link {
  color: #497af2;
  cursor: pointer;
  text-decoration-line: none;
}
.login_page__success__reasons {
  opacity: 0;
  transition: all 0.5s ease;
  margin-top: 1.625rem;
  color: #364a5d;
}
.login_page__success__reasons--visible {
  opacity: 1;
}
.login_page__success__reasons__list {
  list-style: circle;
  margin-left: 1rem;
  margin-top: 1rem;
}
.signup_occasionally_emails_text {
  color: #737373;
  font-size: 0.9rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.signup_occasionally_emails_text a {
  font-size: 0.9rem;
  font-style: normal;
  text-decoration: none;
  color: #497af2;
}
.signup_multi--link {
  color: #497AF2;
  text-decoration-line: underline;
  font-size: inherit;
}
