.plan_price__monthly {
  display: flex;
  justify-content: center;
  padding-bottom: 0.8rem;
}

.plan_price__yearly {
  text-align: center;
  color: #6e7d8a;
  font-size: 14/16rem;
}

.plan_price__monthly__currency {
  /*font-size: 14rem/16;
  padding-top: 0.6rem;
  */
  font-size: 1.8rem;
  color: #6e7d8a;
  align-self: flex-start;
}
.plan_price__monthly__rate {
  font-size: 1.8rem;
  margin-left: 0.5rem;
  color: #364a5d;
  align-self: center;
}
.plan_price__monthly__message {
  font-size: 12/16rem;
  color: #364a5d;
  align-self: center;
}
