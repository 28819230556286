.success_tick_icon {
  background: #249d56;
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  position: relative;
  margin: 0 auto;
}
.success_tick_icon__img {
  background: url("./images/round_done_white_48dp.png") no-repeat top left;
  background-size: contain;
  background-position: center;
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.success_payment__thank_you {
  margin-top: 2rem;
  color: #364a5d;
  text-align: center;
  font-weight: bold;
  font-size: 24rem/16;
}
.success_payment__payment_recieved {
  color: #6e7d8a;
  text-align: center;
  font-size: 24rem/16;
}

.success_payment__button {
  margin: 0 auto;
  text-align: center;
  margin-top: 24rem/16;
}
