.login_page {
  width: 100%;
}

.login_page__left__app_title__text {
  display: inline-block;
  position: relative;
}

.login_page__left__app_title__trademark {
  font-weight: normal;
  position: absolute;
  font-size: 22px;
  top: -5px;
  right: 0;
}

.login_page__left {
  margin-left: 35%;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 2em;
  width: 40%;
  transition: all 0.5s ease;
  text-align: left;
  display: flex;
  height: 100vh;
  flex-direction: column;
  .time_peace_button {
    margin-right: 1em;
  }
  .text_box__input {
    border-radius: 2px;
    border-bottom-color: #6e7d8a;
    height: 56rem/16;
    background-color: rgba(188, 188, 188, 0.1);
    max-width: 100%;
    padding-left: 1rem;
    padding-top: 1rem;
  }
  .text_box {
    
  }
}
.login_page__login__signup {
  position: absolute;
  top: 71rem/16;
  right: 57rem/16;
  .time_peace_button {
    margin-left: 1rem;
  }
}

.login_page__login {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 400px;
}

.login_page__login__separator {
  flex: 1;
}

.login_page__login_passwordReset {
  color: green;
  font-weight: bold;
}

.login_page__login__signup {
  color: #737373;
  font-size: 0.9em;
}

.login_page__login__signup__link {
  color: #497af2;
}

.login_page__left__app_title {
  color: #497af2;
  /*border: 2px solid #497af2; */
  height: 54px;
  width: 155px;
  line-height: 54px;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  img {
    width: 100%;
    height: auto;
  }
}

.login_page__left__get_started {
  margin-top: 3em;
}

.login_page__status {
  max-height: 0;
  opacity: 0;
  color: #737373;
  font-size: 0.9em;
}

.login_page__status--visible {
  margin-top: 2em;
  margin-bottom: 1em;
  opacity: 1;
  max-height: 3em;
    text-align: center;
    color: red;
}

.login_page__status--error {
  color: #e74c3c !important;
}

.login_page__left__sign_in_below {
  margin-bottom: 1.2em;
  color: #364a5d !important;
  font-size: 18rem/16 !important;
}
.login_page__left__get_started {
  font-size: 48rem/16;
}

.login_page__image {
  position: fixed;
  width: 30%;
  top: 0;
  left: 0;
  height: 100vh;
  transition: all 0.5s ease;
  opacity: 1;
  background: url("../payments/images/overlay-img-sign-up.png") no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
}

.login_page--bigger-left {
  .login_page__left {
    padding-left: 5%;
  }
}

.login_page--full-left {
  .login_page__left {
    width: 90%;
  }
  .login_page__right {
    display: none;
  }
  .profile_edit_page {
    padding-left: 0;
    padding-right: 0;
    .text_box__area {
      margin-right: 1rem;
    }
    .profile_edit__row > * {
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
    .profile_edit_dialog__title {
      margin-left: 0;
    }
  }
  .admins_page__create {
    .input_with_label {
      width: 50%;
      padding-right: 1rem;
      .text_box__input {
        max-width: none;
      }
    }
  }
}

@media (max-width: 870px) {
  .login_page--full-left {
    .profile_edit_page {
      .profile_edit__row > * {
        padding-left: 0;
        padding-right: 0;
      }
      .text_box__area {
        margin-right: 0rem;
      }
    }
    .admins_page__create {
      .input_with_label {
        width: 100%;
        padding-right: 0rem;
      }
    }
  }
}

.login_page__right__image {
  /*transform: translate(0, -7%);*/
  height: 105vh;
}

.login_page__left__buttons {
  margin-top: 2em;
  display: flex;
  justify-content: center;
}

.payments_layout_logo_img {
  display: none;
}

@media (max-width: 1024px) {
  .login_page__status {
    font-size: 0.8em;
  }
}

@media (max-width: 740px) {
  .login_page__right {
    max-width: 0;
    opacity: 0;
  }
  .login_page__left {
    width: 90%;
    text-align: center;
  }
  .login_page__left__app_title {
    margin-left: auto;
    margin-right: auto;
  }
  .login_page__status {
    font-size: 0.9em;
  }
  .login_page__image {
    display: none;
  }
  .login_page__left {
    margin: 0 auto;
  }

  .login_page__login__signup {
    margin-right: auto;
    margin-left: auto;
    display: block;
    position: relative;
    top: 1rem;
    right: 0;
    left: 0;
  }

    .payments_layout_logo_img {
      display: inline-block;
      background-color: #497af2;
      margin-top: 2rem;
      border: 1px solid #e1e1e1;
    }

    .login_page__left__get_started {
      margin-top: 2rem;
    }

}

.login_page--bigger-left {
  .text_box__input {
    &::placeholder {
      color: #535353 !important;
    }
  }
}

.timepeace_button_green {
  background: #10bb85;
  color: white;
  font-size: 24rem/16;
  padding-right: 50rem/16;
  padding-left: 50rem/16;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 31px;
  border: 0;
  outline: 0;
  cursor: pointer;
  align-self: center;
}

.login_page__looking_for {
  font-size: 18rem/16;
  color: white;
  text-align: center;
  margin-bottom: 20rem/16;
}
.login_page__grow {
  flex-grow: 1;
}

.login_page__image {
  h3 {
    color: white;
    opacity: 1;
    text-align: center;
    padding-left: 2rem;
    padding-right: 2rem;
    max-width: 400rem/16;
    margin-top: 48rem/16;
    font-size: 2rem;
    align-self: center;
  }
  .timepeace_button_green {
    margin-bottom: 84rem/16;
  }
}



.mobile-icons-container {
  display: flex;
    margin: auto;
}

.download-app-store {
  background: url("../../../widgets/calendar/download-app-store.svg") no-repeat top left;
  background-size: 8.25rem;
  width: 8.25rem;
  height: 4rem;
}

.download-app-play {
  background: url("../../../widgets/calendar/google-play-badge.png") no-repeat top left;
  background-size: 9rem;
  width: 9.25rem;
  height: 4rem;
  margin-left: 0.5rem;
}
