.multi_select {
  position: relative;
  display: inline-block;
  flex: 0 1 auto;
  min-width: 7rem;
}

.multi_select__select {
  white-space: nowrap;
}

.multi_select__select--input {
  position: relative;
  .multi_select__select__collapse_icon {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
  }
  .multi_select__select__collapse_icon--closed {
    transform: translateY(-50%) rotate(180deg);
  }
  input {
    padding-right: 1rem;
  }
}

.multi_select__select__label {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  // width: calc(100% - 25px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.multi_select__options__option {
}

.multi_select__select__collapse_icon {
  width: 0;
  height: 0;
  margin-left: 20px;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #737373 transparent;
  transition: all 0.5s ease;
  transition-delay: 0.2s;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  margin-top: 2.5px;
}

.multi_select__select__collapse_icon--opened {
  transform: rotate(0deg);
}

.multi_select__select__collapse_icon--closed {
  transform: rotate(180deg);
}

.multi_select__options {
  width: 100%;
  overflow-x: hidden;
  transition: all 0.5s ease;
  background: white;
  background: #f4f4f4;
  border-radius: 5px;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  min-width: 15em;
  z-index: 2;
  padding: 1rem;
  padding-top: 0.3rem;
}

.multi_select__options--opened {
  max-height: 200px;
  overflow-y: auto;
}

.multi_select__options--closed {
  max-height: 0;
}

.multi_select__options--select {
  .multi_select__options__option {
    &:hover {
      background: gray;
    }
    transition: all 0.5s ease;
    cursor: pointer;
  }
}

.multi_select__options__option {
  line-height: 2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.multi_select__options__option__chk {
}

.multi_select__options__option__label {
}

@media only screen and (max-width: 900px) {
.multiselect--header--label {
  font-size: 4.5vw!important;
    max-width: 96% !important;
}
.multiselect--header {
  max-width: 92% !important;
  float: left;
}
}