.payments_layout {
}
.payments_layout__left {
  position: fixed;
  left: 0;
  top: 0;
  width: 32%;
  background: url("./images/overlay-img-sign-up.png");
  background-size: cover;
  height: 100%;
  background-position: center;
  display: flex;
  flex-direction: column;
  h3 {
    font-size: 2rem;
    color: white;
    max-width: 22rem;
    opacity: 1;
    align-self: center;
    text-align: center;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 48rem/16;
  }
}
.payments_layout__right {
  margin-left: 40%;
  padding-top: 10.3rem;
  padding-right: calc(40% - 22rem);
}

.payments_layout__left__logo {
  background: #497af2;
  display: inline-block;
  padding: 2px;
  align-self: center;
  margin-top: (57rem/16);
}

.login_layout__left__logo {
  background: #497af2;
  display: inline-block;
  padding: 2px;
  align-self: center;
  margin-top: 3.5625rem;
  height: 4.1rem;
}

.payments_layout__left__logo__img {
  width: 11rem;
}
.payments_layout__right {
}
.payments_layout__right__content {
}

@media (max-width: 870px) {
  .payments_layout__left {
    display: none;
  }
  .payments_layout__right {
    margin: 0 auto;
    padding-top: 2rem;
    display: flex;
    justify-content: center;
  }
}
