.form-component {
  background: #f0f0f0;
  border-radius: 2px;
  height: 3em;
  outline: 0;
  border: 0;
  font-size: 0.9rem;
}
.form-time-component {
  background: #f0f0f0;
  border-radius: 2px;
  height: 3em;
  outline: 0;
  border: 0;
  font-size: 0.9rem;
  height: 2.5em;
  text-align: center;
}
.form-time-component--inline-container {
  display: inline-block;
  margin-right: 0.8rem;
}
.close_btn {
  background: url('../../styles/exit@2x.png');
  position: absolute;
  top: 0.8em;
  right: 0.8em;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-size: 100% 100%;
  z-index: 1;
}
.event_dialog__time__to {
  margin-right: 0.8rem;
}
.event_dialog__close_btn {
  background: url('../../styles/exit@2x.png');
  position: absolute;
  top: 0.8em;
  right: 0.8em;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-size: 100% 100%;
  z-index: 1;
}
.event_form_dialog__time__row {
  margin-top: 1.5rem;
}
.event_form_button__row__pns_info {
  flex: 1;
}
.event_form_dialog__row,
.event_form_simple_dialog__row {
  display: flex;
  margin-top: 1.5em;
}
.event_form_dialog__row:first-child,
.event_form_simple_dialog__row:first-child {
  margin-top: 0;
}
.event_form_dialog__row > div,
.event_form_simple_dialog__row > div {
  flex-basis: 50%;
  margin-left: 0.5em;
  margin-right: 0.5em;
  margin-top: 0;
}
.event_form_dialog__row > div:last-child,
.event_form_simple_dialog__row > div:last-child {
  margin-right: 0;
}
.event_form_dialog__row > div:first-child,
.event_form_simple_dialog__row > div:first-child {
  margin-left: 0;
}
.event_form_dialog__row .event_form_dialog__row__recurring_container,
.event_form_simple_dialog__row .event_form_dialog__row__recurring_container {
  flex-basis: 18em;
}
.event_form_dialog__row .text_box--area,
.event_form_simple_dialog__row .text_box--area {
  flex-basis: 100%;
  margin-right: 0;
  margin-left: 0;
}
.event_form_dialog__row .text_box__input,
.event_form_simple_dialog__row .text_box__input {
  background: #f0f0f0;
  max-width: 535px;
  height: 3em;
  border-radius: 2px;
}
.event_form_dialog__row .text_box__counter,
.event_form_simple_dialog__row .text_box__counter {
  max-width: 535px;
}
.event_form_dialog__row .text_box__area,
.event_form_simple_dialog__row .text_box__area {
  background: #f0f0f0;
  height: 5em;
  border-radius: 2px;
  resize: none;
}
.event_form_dialog__row .text_box__area_big,
.event_form_simple_dialog__row .text_box__area_big {
  height: 7em;
}
.event_form_dialog__row .text_box__area_big_mobile,
.event_form_simple_dialog__row .text_box__area_big_mobile {
  height: 19em;
}
.event_form_simple_dialog__row {
  display: block;
}
.event_form_button__row {
  margin-top: 1.5em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.event_form_button__row .time_peace_button--delete {
  margin-right: 2em;
}
.event_form_button__row .checkbox__container {
  white-space: nowrap;
  margin-right: 3em;
}
.event_form_dialog__row__recurring__options__row {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 1em;
  justify-content: space-between;
}
.event_form_dialog__row__recurring__options__row > *:last-child {
  /*margin-right: 0.5em;*/
}
.event_form_dialog__row__recurring__options__row .text_box {
  margin-top: 0;
}
.event_form_dialog__row__recurring__options__row__every_text {
  flex: 0 0 2.5rem;
  margin-top: 0 !important;
  margin-bottom: 0;
  margin-right: 0.8em;
}
.event_form_dialog__row__recurring__options__row__every_text input {
  text-align: right;
}
.event_form_dialog__row__recurring__options__row__every_select {
  flex: 1 0;
}
.event_form_dialog__row__recurring__options__row__every_select input {
  padding-left: 5px;
}
.event_form_dialog__row__recurring__options__row__every_select .auto_select__item {
  padding-left: 5px;
}
.event_form_dialog__row__recurring__options__row__message {
  flex: 0 0 7em;
  margin-right: 0.5em;
  align-self: center;
}
.event_form_dialog__row__recurring__options {
  overflow: hidden;
  transition: all 0.5s ease;
}
.event_form_dialog__row__recurring__options--visible {
  max-height: 18em;
  opacity: 1;
  overflow: visible;
}
.event_form_dialog__row__recurring__options--invisible {
  max-height: 0;
  opacity: 0;
}
.event_form_dialog__row__recurring__options__row--end input {
  width: 100% !important;
}
.event_form_dialog__row__recurring__options__row__every_select {
  max-width: 10.5rem;
}
@media (max-width: 870px) {
  .event_form_dialog__row__recurring__options__row {
    display: block;
  }
  .event_form_dialog__row__recurring__options__row .event_form_dialog__row__recurring__right {
    margin-top: 1rem;
  }
  .event_form_dialog__row__recurring__options__row .recurring__options__repeats_on_label {
    margin-left: 0;
    padding-left: 0;
    flex: 0 0 8em;
    margin-right: 0.5em;
  }
}
.event_dialog .text_box__input[type="text"],
.event_dialog .text_box__input[role="combobox"] {
  padding-left: 1rem;
  padding-right: 1rem;
}
.event_dialog .text_box__area {
  padding: 1rem;
}
.event_dialog .event_form_dialog__row__recurring__left input[type="number"] {
  text-align: right;
}
.event_dialog .event_form_dialog__row__recurring__options__row__after_row__value_container input[type="number"] {
  text-align: center;
}
.event_dialog .tag_component__title .auto_select {
  flex: 1 1 auto;
}
.event_dialog .tag_component__title .auto_select:first-child {
  flex: 0 1 50%;
}
.event_dialog .event_form_dialog__row__category_container .auto_select {
  margin-top: 1.2rem;
}
@media (max-width: 760px) {
  .event_form_dialog__row {
    display: block;
    margin-top: 0;
  }
  .event_form_dialog__row > div {
    margin-top: 1.5em;
    margin-left: 0;
    margin-right: 0;
  }
  .event_form_button__row {
    flex-wrap: wrap;
  }
  .event_form_button__row__pns_info {
    flex: 1 0 100%;
  }
  .event_dialog .tag_component__title {
    flex-wrap: wrap;
  }
  .event_dialog .tag_component__title > *:first-child {
    margin-bottom: 0.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .event_dialog .tag_component__title .auto_select:first-child {
    flex-basis: 100%;
  }
  .event_dialog .tag_component__title > *:nth-child(2) {
    margin-left: 0;
    margin-right: 0;
  }
  .ReactModal__Content {
    border: 0 !important;
    border-radius: 0 !important;
  }
  .event_dialog__content {
    padding-bottom: 3rem;
  }
  .event_dialog__content .auto_select,
  .event_dialog__content .multi_select__select--input,
  .event_dialog__content .text_box__area {
    max-width: 535px;
  }
  .event_dialog__content .event_form_dialog__time__row {
    max-width: 20rem;
    flex-wrap: wrap;
  }
}
@media (max-width: 550px) {
  .event_dialog .checkbox__container__message {
    font-size: 0.8rem;
  }
}
@media (max-width: 440px) {
  .event_form_dialog__row__recurring__options__every_text {
    flex-basis: 100%;
  }
  .event_form_dialog__row__recurring__every {
    flex-wrap: wrap;
  }
}
.event_form_button__row__pns_info {
  margin-top: 1rem;
  overflow: hidden;
  transition: all 0.5s ease;
}
.event_form_button__row__pns_info--invisible {
  max-height: 0;
  opacity: 0;
}
.event_form_button__row__pns_info--visible {
  max-height: 16rem;
  opacity: 1;
}
.event_form_dialog__row__recurring__options__row__after_row {
  display: flex;
  margin-top: 0.8em;
  align-items: center;
}
.event_form_dialog__row__recurring__options__row__after_row input {
  width: 9.5rem;
}
.event_form_dialog__row__recurring__options__row__after_row .text_box__input {
  padding-left: 0.5em;
}
.event_form_dialog__row__recurring__options__row__after_row .text_box {
  margin-top: 0;
}
.event_form_dialog__row__recurring__options__row__after_row__chk_container {
  flex: 0 0 8em;
  margin-right: 0.5em;
}
.event_dialog__delete_overlay__options__option {
  margin-top: 1em;
}
.event_dialog__delete_overlay__options__option .checkbox__container__checkbox--checked {
  background: #e74c3c;
  border-color: #e74c3c;
}
.event_dialog__delete_overlay .time_peace_button {
  background: #e74c3c;
}
.event_dialog {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.event_dialog__content {
  flex: 1 1;
  overflow: auto;
}
.event_form_dialog__after_box {
  background: #f0f0f0;
  border-radius: 5px;
}
.event_form_dialog__after_box input {
  width: 3em;
}
.event_form_dialog__calendar,
.event_form_dialog__category {
  display: block !important;
}
.event_form_dialog__row__recurring__month_day {
  background: #f0f0f0;
  border-radius: 5px;
  flex: 0 1 9.5rem;
}
.event_form_dialog__row__recurring__month_day input {
  max-width: 5rem;
}
.event_form_dialog__recurring__row {
  display: block;
}
.event_form_dialog__row__recurring__left {
  display: flex;
  flex: 0 0 18rem;
}
.event_form_dialog__row__recurring__right {
  display: flex;
  flex: 1;
}
.recurring__options__repeats_on_label {
  flex: 0 0 auto;
  padding-right: 0.5rem;
  white-space: nowrap;
}
.event_dialog__all_day_check_container {
  margin-right: 0.5rem;
}
.event_dialog__timezone_select {
  display: inline-block;
  margin-top: 0.5rem;
  width: 12.5rem;
}
.event_dialog__timezone_select .text_box__input {
  background: #f0f0f0;
  max-width: 495px;
  height: 3em;
  border-radius: 2px;
  font-size: 0.8rem;
}
.event_dialog__timezone_select .auto_select__container__button {
  right: 5px;
}
.event_dialog__errors_validation {
  margin-right: 0.5rem;
  font-size: 0.8rem;
  color: #e74c3c !important;
}
.event_dialog__timezone_button {
  padding-left: 0.5rem;
  line-height: 2rem;
  padding-right: 0.5rem;
  margin-top: 0.5rem;
  cursor: pointer;
  align-self: center;
  border-radius: 3px;
  transition: all 0.5s ease;
  max-width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.event_dialog__timezone_button:hover {
  background: #c2c2c2;
}
.event_form_dialog__tags {
  display: flex;
  flex-wrap: wrap;
}
.event_form_dialog__tag {
  background: red;
  border-radius: 5px;
  color: #fff;
  margin-right: 1rem;
  margin-top: 0.5rem;
  padding: 4px;
  font-size: 14px;
}
.event_form_dialog__tag__cross {
  background: transparent;
  border: 0;
  cursor: pointer;
  color: white;
}
.event_form_dialog__row__pns_container {
  flex: 1;
}
.event_dialog__delete_overlay__push_notifications__info {
  margin-top: 1rem;
  padding-left: 0.5rem;
  transition: opacity 0.5s ease 0.3s, max-height 0.5s ease;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}
.event_dialog__delete_overlay__push_notifications__info .text_box__input {
  max-width: 100%;
}
.event_dialog__delete_overlay__push_notifications__info--visible {
  max-height: 200px;
  opacity: 1;
}
.event_dialog__delete_overlay__push_notifications {
  margin-top: 2rem;
}
@media only screen and (max-width: 700px) {
  .event_form_dialog__row__recurring__options__row__after_row {
    flex-direction: column;
    display: block;
    margin-top: 0;
    align-items: center;
    margin-bottom: 10px;
  }
  .event_form_dialog__row__recurring__options__row__after_row input {
    width: 9.5rem;
  }
  .event_form_dialog__row__recurring__options__row__after_row .text_box__input {
    padding-left: 0.5em;
  }
  .event_form_dialog__row__recurring__options__row__after_row .text_box {
    margin-top: 0;
  }
  .event_form_dialog__row__recurring__options__row__after_row .react-datepicker-popper {
    margin-left: -35px;
  }
  .event_form_dialog__row__recurring__options__row__after_row .date_picker {
    width: 100%;
  }
  .event_form_dialog__row__recurring__options__row__after_row .date_picker .react-datepicker-wrapper {
    width: 100%;
  }
  .event_form_dialog__row__recurring__options__row__after_row .date_picker .react-datepicker__input-container {
    width: 100%;
  }
  .event_form_dialog__row__recurring__options__row__after_row .date_picker .date_picker__input {
    width: 100%;
  }
  .event_form_dialog__row__recurring__options__row__after_row .event_form_dialog__after_box {
    background-color: transparent;
  }
  .event_form_dialog__row__recurring__options__row__after_row .event_form_dialog__after_box .text_box__input {
    min-width: 50%;
  }
  .event_form_dialog__row__recurring__options__row__after_row .event_form_dialog__row__recurring__options__row__after_row__chk_container {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .date_picker {
    width: 100%;
  }
  .date_picker .react-datepicker-wrapper {
    width: 100%;
  }
  .date_picker .react-datepicker__input-container {
    width: 100%;
  }
  .date_picker .date_picker__input {
    width: 100%;
  }
  .event_dialog__timezone_button {
    max-width: 100%;
  }
  .event_form_button__row__pns_info .input_with_label {
    margin-bottom: 1rem;
  }
  .event_dialog__timezone_select {
    width: 100%;
    margin-top: 2rem;
  }
  .event_dialog__delete_overlay__push_notifications .event_dialog__delete_overlay__push_notifications__info--desc {
    margin-top: 1rem;
  }
  .event_dialog__delete_overlay__push_notifications .text_box__area {
    padding: 0;
  }
  .event_form_dialog__row__recurring__options__row__every_text {
    min-width: 50%;
  }
  .event_dialog .event_form_dialog__row__recurring__left input[type=number] {
    text-align: center;
  }
  .event_form_dialog__row__recurring__options--visible {
    max-height: 100%;
  }
}
