.form-component {
  background: #f0f0f0;
  border-radius: 2px;
  height: 3em;
  outline: 0;
  border: 0;
  font-size: 0.9rem;
}
.form-time-component {
  background: #f0f0f0;
  border-radius: 2px;
  height: 3em;
  outline: 0;
  border: 0;
  font-size: 0.9rem;
  height: 2.5em;
  text-align: center;
}
.form-time-component--inline-container {
  display: inline-block;
  margin-right: 0.8rem;
}
.close_btn {
  background: url('../../styles/exit@2x.png');
  position: absolute;
  top: 0.8em;
  right: 0.8em;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-size: 100% 100%;
  z-index: 1;
}
.auto_select__container {
  display: block !important;
}
.auto_select__container__button {
  position: absolute;
  top: 0%;
  right: 0.3rem;
  width: 1rem;
  height: 100%;
  cursor: pointer;
}
.auto_select__container__button .auto_select__container__button__arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7.5px 0 7.5px;
  border-color: #737373 transparent transparent transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.5s ease;
}
.auto_select__container__button--open .auto_select__container__button__arrow {
  transform: translate(-50%, -50%) rotate(0deg);
}
.auto_select__container__button--close .auto_select__container__button__arrow {
  transform: translate(-50%, -50%) rotate(180deg);
}
.auto_select {
  position: relative;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  transition: all 0.5s ease;
}
.auto_select--invisible {
  opacity: 0;
}
.auto_select__container > div {
  z-index: 1;
  transform: translate(0, -2px);
  background: transparent !important;
  position: absolute;
  top: 3em;
  left: 0;
  width: 100%;
  min-height: auto;
  max-height: 10em;
  overflow: auto;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
}
.auto_select__item {
  transition: all 0.5s ease;
  background: #f0f0f0;
  line-height: 2.5rem;
  height: 2.5rem;
  padding-left: 2em;
  z-index: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9rem;
}
.auto_select__item--highlight {
  background: gray;
}
.auto_select--with-material-placeholder {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 4rem;
}
.auto_select--with-material-placeholder input {
  height: 2rem;
}
.auto_select__placeholder {
  position: absolute;
  top: 1.8rem;
  transition: all 0.3s ease;
  color: #d3d3d3;
  padding-left: 1rem;
}
.auto_select__placeholder--top {
  font-size: 0.8rem;
  top: 1rem;
}
.auto_select__placeholder--focused {
  color: #4979f3;
  font-size: 0.8rem;
  top: 1rem;
}
